import React from 'react';
import styles from "./styles.module.scss";
import {Translation} from 'educat-common-web';
import Accordion from "../Accordion";

interface IFooterProps {}

const Footer: React.FC<IFooterProps> = () => {
    const year = new Date().getFullYear().toString();

    const accordionItems = [
        {
            title: 'footer.addressColumn.title',
            content: ['footer.addressColumn.address1', 'footer.addressColumn.address2', 'footer.addressColumn.country', 'footer.addressColumn.nip', 'footer.addressColumn.regon'],
            titleClassName: `${styles.columnTitle}`,
            contentClassName: `${styles.columnDetails}`,
        },
        {
            title: 'footer.contactColumn.title',
            content: ['footer.contactColumn.phone', 'footer.contactColumn.email', 'footer.contactColumn.correspondenceAddressTitle', 'footer.contactColumn.address1', 'footer.contactColumn.address2'],
            titleClassName: `${styles.columnTitle}`,
            contentClassName: `${styles.columnDetails}`,
        },
    ];

    const renderSocialIcons = () => {
        return(
            <div className={styles.socialIconsWrapper}>
                <a href="https://www.facebook.com/profile.php?id=100063901011756"
                   className={styles.socialLink}
                   target="_blank"
                   rel="noreferrer">
                    <span className={`${styles.icon} ${styles.facebook}`}/>
                    <span className="sr-only">
                        <Translation text="footer.socialIcons.facebook" />
                    </span>
                </a>
                <a href="https://www.instagram.com/educat.study/"
                   className={styles.socialLink}
                   target="_blank"
                   rel="noreferrer">
                    <span className={`${styles.icon} ${styles.instagram}`}/>
                    <span className="sr-only">
                        <Translation text="footer.socialIcons.instagram" />
                    </span>
                </a>
                <a href="https://www.linkedin.com/company/educat-study/"
                   className={styles.socialLink}
                   target="_blank"
                   rel="noreferrer">
                    <span className={`${styles.icon} ${styles.linkedin}`}/>
                    <span className="sr-only">
                        <Translation text="footer.socialIcons.linkedin" />
                    </span>
                </a>
                <a href="https://www.youtube.com/channel/UCawkfIMVzcPVg36xPhRNe2A/featured"
                   className={styles.socialLink}
                   target="_blank"
                   rel="noreferrer">
                    <span className={`${styles.icon} ${styles.youtube}`}/>
                    <span className="sr-only">
                        <Translation text="footer.socialIcons.youtube" />
                    </span>
                </a>
            </div>
        )
    }

    const renderDesktopFooterContent = () => {
        return (
            <div className={styles.footerContainer}>
                <div className={styles.footerRow}>
                    <div className={styles.footerColumn}>
                        <div className={styles.logo} />
                        <div className={styles.footerData}>
                            <p className={styles.columnDetails}>
                                <Translation text="footer.educat" config={{year: year}}/>
                            </p>
                            <p className={styles.columnDetails}>
                                <Translation text="footer.copyright" />
                            </p>
                        </div>
                    </div>

                    <div className={styles.footerColumn}>
                        <div className={`${styles.footerData} ${styles.customFooterData}`}>
                            <h3 className={styles.columnTitle}><Translation text="footer.addressColumn.title" /></h3>
                            <p className={styles.columnDetails}>
                                <Translation text="footer.addressColumn.address1" />
                            </p>
                            <p className={styles.columnDetails}>
                                <Translation text="footer.addressColumn.address2" />
                            </p>
                            <p className={styles.columnDetails}>
                                <Translation text="footer.addressColumn.country" />
                            </p>
                        </div>
                        <div className={styles.footerData}>
                            <p className={styles.columnDetails}>
                                <Translation text="footer.addressColumn.nip" />
                            </p>
                            <p className={styles.columnDetails}>
                                <Translation text="footer.addressColumn.regon" />
                            </p>
                        </div>
                    </div>

                    <div className={styles.footerColumn}>
                        <div className={styles.footerData}>
                            <h3 className={styles.columnTitle}><Translation text="footer.contactColumn.title" /></h3>
                            <a className={styles.columnDetails} href="tel:+48534313540"> <Translation text="footer.contactColumn.phone" /> </a>
                            <a className={styles.columnDetails} href="mailto:hello@educat.study">  <Translation text="footer.contactColumn.email" /></a>
                        </div>
                        <div className={styles.footerData}>
                            <p className={styles.columnDetails}>
                                <Translation text="footer.contactColumn.correspondenceAddressTitle" />
                            </p>
                            <p className={styles.columnDetails}>
                                <Translation text="footer.contactColumn.address1" />
                            </p>
                            <p className={styles.columnDetails}>
                                <Translation text="footer.contactColumn.address2" />
                            </p>
                          </div>
                    </div>

                    <div className={styles.footerColumn}>
                        <div className={styles.footerData}>
                            {/*<h3 className={styles.columnTitle}><Translation text="footer.linksColumn.title" /></h3>*/}
                            {/*<a className={`${styles.columnDetails} ${styles.link}`} href=""><Translation text="footer.linksColumn.policies" /></a>*/}
                        </div>

                        <div className={styles.footerData}>
                            {renderSocialIcons()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const renderMobileFooterContent = () => {
        return (
            <div className={styles.footerContainerMobile}>
                <div className={styles.footerRow}>
                    <div className={styles.footerColumn}>
                        <div className={styles.footerData}>
                            <Accordion items={accordionItems} />
                        </div>
                    </div>
                </div>

                {/*<div className={styles.footerRow}>*/}
                {/*    <div className={styles.footerColumn}>*/}
                {/*        <div className={styles.footerData}>*/}
                {/*            <h3 className={styles.columnTitle}><Translation text="footer.linksColumn.title" /></h3>*/}
                {/*            <a className={`${styles.columnDetails} ${styles.link}`} href=""><Translation text="footer.linksColumn.policies" /></a>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div className={styles.footerRow}>
                    <div className={styles.footerColumn}>
                        <div className={`${styles.footerData} ${styles.centered}`}>
                           <div className={styles.logo}/>
                        </div>
                    </div>

                    <div className={styles.footerRow}>
                        <div className={styles.footerColumn}>
                            <div className={styles.footerData}>
                                {renderSocialIcons()}
                            </div>
                        </div>
                    </div>

                    <div className={styles.footerRow}>
                        <div className={styles.footerColumn}>
                            <div className={`${styles.footerData} ${styles.centered}`}>
                                <p className={styles.columnDetails}><Translation text="footer.copyrightMobile" config={{year: year}}/></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <footer className={styles.footer}>
            <h2 className="sr-only">
                <Translation text="footer.srTitle" />
            </h2>
            {renderDesktopFooterContent()}
            {renderMobileFooterContent()}
        </footer>
    )
}

export default Footer;
